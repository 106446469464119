import React from "react";
import "./style.css";

import img3 from "../../assets/images/home/poliklinika.jpg";

export const AboutContent = () => {
    return (
        <div className="about-content">
            <div>
                <h2>Կառլեն Եսայանի անվան պոլիկլինիկա</h2>
                <p>
                    Հիմնադրվել է 2012 թվականին, Երևանի քաղաքապետի կողմից՝ որպես իրավաբանական անձ։
                    Բուժհաստատությունն ունի հետևյալ կաբինետները՝
                    <div className="content-table">
                    <ul>
                        <li>Թերապևտիկ</li>
                        <li>Վարակաբանական</li>
                        <li>Համաճարակաբանական </li>
                        <li>Իմունականխարգելմանն </li>
                        <li>Ուռուցքաբանական</li>
                        <li>Վիրաբուժական </li>
                        <li>Վնասվածքաբանական </li>
                        <li>Ֆիզիոթերապևտիկ </li>
                        <li>Քիթ-կոկորդ-ականջաբանական </li>
                        <li>Ակնաբուժական</li>
                        <li>Ներզատաբանական </li>
                        <li>Նյարդաբանական </li>
                        <li>Կանանց կոնսուլտացիա </li>
                        <li>Գաստրոէնտերոլոգիական</li>
                        <li>Գենետիկ</li>
                        <li>Սրտաբանական</li>
                        <li>Մաշկավեներական</li>
                        <li>Ուրոլոգիական</li>
                    </ul>
                    <ul>
                        <li>Լաբորատոր-գործիքային ախտորոշիչ հետազությունների՝</li>
                        <p>լաբորատորիա</p>
                        <p>ուլտրաձայնային</p>
                        <p>էլեկտրոսրտագրության</p>
                        <p>էխոսրտագրության</p>
                        <p>Հոլտեր</p>
                        <p>դուպլեքս</p>
                        <p>դոպլեր</p>
                        <p>ռենտգեն</p>
                    </ul>
                    </div>
                    
                </p>
            </div>
            <img src={img3} alt="3" />
        </div>
    );
};
